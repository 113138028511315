import { Button, Divider, Flex, Heading, Text, TextField } from '@aws-amplify/ui-react';
import { useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Buffer } from 'buffer';

function TestConfigPage() {
  const [flags, setFlags] = useState<Array<string>>([]);
  const [didCopy, setDidCopy] = useState(false);
  const config = {
    devMode: false,
    backends: [],
    featureFlagOnlyDevMode: true,
    featureFlags: flags.filter((aValue) => !!aValue),
  };
  const fullDevMode = {
    devMode: true,
    backends: [
      {
        id: 'atestbankid',
        name: 'BRUMM+BankId',
        androidClientId: 'tnn-mitt-telenor-next-android',
        iosClientId: 'tnn-mitt-telenor-next-ios',
        apiHost: 'https://atest-apigw.telenor.no',
        loginType: 'telenorIdPlusTest',
      },
      { id: 'atest', name: 'BRUMM', apiHost: 'https://atest-apigw.telenor.no', loginType: 'telenorIdPlusTest' },
    ],
  };
  const qrValue =
    'mitttelenor://testconfig/' +
    encodeToBase64(JSON.stringify(flags.filter((aValue) => !!aValue).length > 0 ? config : fullDevMode));
  const qrRef = useRef<SVGSVGElement | null>(null);

  const downloadQRCode = () => {
    if (qrRef.current) {
      const svgElement = qrRef.current;
      const serializer = new XMLSerializer();
      const svgData = serializer.serializeToString(svgElement);

      // Create a blob from the serialized SVG data
      const blob = new Blob([svgData], { type: 'image/svg+xml' });
      const url = URL.createObjectURL(blob);

      // Create a temporary link to trigger download
      const link = document.createElement('a');
      link.href = url;
      link.download = 'qrcode.svg';
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Flex direction="column" alignItems={'center'}>
      <Heading level={4}>{'Activate test config on your device'}</Heading>
      <Divider size="small" />
      <Heading level={5}>{'📱 Test mode'}</Heading>
      <Text maxWidth={800}>
        {
          "Click the button if you want to enable test mode on the device you're on, or scan the QR code from a device. The app needs to be installed for this to work, and you will be informed that the test config has been received. If that doesn't happen you probably need a newer client."
        }
      </Text>
      <Button variation="primary" onClick={() => window.open(qrValue, 'self')}>
        {'Activate on this device'}
      </Button>
      <Button
        variation="primary"
        onClick={() =>
          navigator.clipboard
            .writeText(qrValue)
            .then(() => setDidCopy(true))
            .catch(() => setDidCopy(false))
        }>
        {didCopy ? '✅' : 'Copy Link'}
      </Button>
      <QRCodeSVG
        value={qrValue}
        size={400} // Size of the QR code
        bgColor={'#ffffff'} // Background color
        fgColor={'#282828'} // Foreground color
        level={'H'} // Error correction level ('L', 'M', 'Q', 'H')
        ref={qrRef}
      />

      <Button variation="primary" onClick={downloadQRCode}>
        {'Download as SVG'}
      </Button>
      <Divider></Divider>
      <Heading>{'BETA'}</Heading>
      <Text>{'Add a list of comma separated feature flag name, if you only want to give access to these flags'}</Text>
      <TextField
        width={400}
        placeholder="featureFlagenableEsimApis, "
        label="Feature Flags"
        value={flags.join(', ')}
        onChange={(event) => {
          setDidCopy(false);
          setFlags(event.currentTarget.value.split(',').map((value) => value.trim()));
        }}
      />
    </Flex>
  );
}

const encodeToBase64 = (input: string): string => {
  return Buffer.from(input, 'utf-8').toString('base64');
};

export default TestConfigPage;
