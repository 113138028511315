import { FcCellPhone } from 'react-icons/fc';
import {
  Alert,
  Badge,
  Button,
  FieldGroupIcon,
  Flex,
  Heading,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@aws-amplify/ui-react';
import React, { useEffect, useState } from 'react';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { generateClient, GraphQLQuery } from 'aws-amplify/api';
import {
  ConnectIdLogin,
  DeleteConnectIdLoginInput,
  DeleteConnectIdLoginMutation,
  ListConnectIdLoginsQuery,
  ListTestAccountsQuery,
  LoginState,
} from '../../API';
import RelativeTime from 'react-time-ago';
import { fetchAuthSession } from 'aws-amplify/auth';

const refreshEndpoint = 'https://api.mitt-telenor.univex.no/refreshAccount';
const refreshAccountAsync = async (connectId: string, jwt: string) => {
  console.log('refresh', connectId);
  const result = await fetch(`${refreshEndpoint}?connectId=${connectId}`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      InvocationType: 'Event',
    },
  });
  console.log(connectId, result);
  return {
    status: result.status,
  };
};

const apiClient = generateClient();
const fetchConnectIds = async () => {
  const allConnectIds = await apiClient.graphql<GraphQLQuery<ListConnectIdLoginsQuery>>({
    query: queries.listConnectIdLogins,
    variables: { limit: 1000 },
  });
  return allConnectIds.data?.listConnectIdLogins?.items;
};

const deleteConnectId = async (item: ConnectIdLogin) => {
  //Get affected test accounts by this deletion
  const results = await apiClient.graphql<GraphQLQuery<ListTestAccountsQuery>>({
    query: queries.listTestAccounts,
    variables: {
      filter: {
        connectId: { eq: item.connectId },
      },
    },
  });
  const testAccountsToDelete = results.data?.listTestAccounts?.items.map((anItem) => anItem?.id);
  if (testAccountsToDelete && testAccountsToDelete.length > 0) {
    const transactions = testAccountsToDelete?.map(
      (identifier, index) => `mutation${index}: deleteTestAccount(input: {id: "${identifier}"}) { id }`,
    );
    await apiClient.graphql({ query: `mutation batchMutation {${transactions}}` });
  }

  //Finally delete the connectId
  const deleteInput: DeleteConnectIdLoginInput = {
    id: item.id,
  };
  await apiClient.graphql<GraphQLQuery<DeleteConnectIdLoginMutation>>({
    query: mutations.deleteConnectIdLogin,
    variables: { input: deleteInput },
  });
};

const UpdateMany: React.FC<{
  title: string;
  connectIds: string[];
  onUpdate: (connectId: string) => Promise<boolean>;
  setChangeCount: (value: React.SetStateAction<number>) => void;
}> = ({ title, connectIds, onUpdate, setChangeCount }) => {
  const [state, setState] = useState<{ type: 'ready' | 'willing' | 'inProgress' | 'done' }>({ type: 'ready' });
  return (
    <>
      <Flex alignItems={'center'} justifyContent={'space-between'} flex={1}>
        {state.type === 'ready' ? (
          <Button
            onClick={() => {
              console.log(connectIds);
              setState({ type: 'willing' });
            }}>
            {title}
          </Button>
        ) : state.type === 'willing' ? (
          <Alert variation="warning" borderRadius={'large'}>
            {'Are you sure?      '}
            <Button
              // disabled={true}
              onClick={() => {
                setState({ type: 'inProgress' });
                Promise.allSettled(
                  connectIds.map(async (anId) => {
                    await onUpdate(anId);
                  }),
                ).then(() => {
                  setTimeout(() => {
                    setState({ type: 'done' });
                    setChangeCount((previousCount) => previousCount + 1);
                  }, 2000);
                });
              }}>
              {'Yes, I want to  update ' + connectIds.length.toString() + ' Accounts.'}
            </Button>
          </Alert>
        ) : state.type === 'inProgress' ? (
          <Loader />
        ) : (
          <Alert borderRadius={'large'} variation="success">
            {'started updating process, this will take while'}
          </Alert>
        )}
      </Flex>
    </>
  );
};

const Addcard: React.FC<{
  onCreate: (msisdn: string) => Promise<void>;
  parentError?: string;
}> = ({ onCreate, parentError }) => {
  const [userInput, setUserInput] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const mergedError = parentError || error;
  const hasError = parentError !== undefined || error.length > 0;
  return (
    <TextField
      placeholder="999 xx xx"
      label="Add/Update connect Id"
      type={'tel'}
      value={userInput}
      disabled={isLoading}
      maxLength={8}
      errorMessage={mergedError}
      hasError={hasError}
      onChange={(e) => setUserInput(e.currentTarget.value)}
      outerEndComponent={
        <Button
          disabled={userInput?.length !== 8 || isLoading}
          variation="primary"
          onClick={async () => {
            try {
              setIsLoading(true);
              await onCreate(userInput!);
              setUserInput('');
            } catch (error) {
              setError(JSON.stringify(error));
            } finally {
              setError('');
              setIsLoading(false);
            }
          }}>
          {isLoading ? <Loader /> : 'Add/Update'}
        </Button>
      }
      innerStartComponent={
        <FieldGroupIcon ariaLabel="">
          <FcCellPhone />
        </FieldGroupIcon>
      }
    />
  );
};

const ConnectIdRow: React.FC<{
  item: ConnectIdLogin | null;
  onDelete: (itemId: string) => Promise<void>;
  onUpdate: (itemId: string) => Promise<void>;
}> = ({ item, onDelete, onUpdate }) => {
  const [update, setUpdate] = useState(false);
  if (!item) {
    return null;
  }
  return (
    <TableRow>
      <TableCell>{item.connectId}</TableCell>
      <TableCell>
        {item.state === LoginState.IN_PROGRESS ? (
          <Loader />
        ) : item.state === LoginState.FAILED ? (
          <Alert borderRadius={'large'} variation="error" heading={'Invalid state of login'}>
            {'Something went wrong with this account, please remove it and try to add it again'}
          </Alert>
        ) : item.accessToken && item.refreshToken && item.pkce ? (
          parseInt(item.expiryDate!) - new Date().getTime() / 1000.0 >= 0 ? (
            '✅ Active'
          ) : (
            '⚠️ Token expired, but still refreshable'
          )
        ) : (
          <Alert borderRadius={'large'} variation="error" heading={'Invalid state of login'}>
            {'Something went wrong with this account, please remove it and try to add it again'}
          </Alert>
        )}
      </TableCell>
      <TableCell>
        <Badge>
          <RelativeTime title="Added" date={new Date(item.updatedAt)} />
        </Badge>
      </TableCell>
      <TableCell>
        {
          <Flex shrink={1} alignItems={'center'} justifyContent={'space-between'}>
            {update ? (
              <Loader />
            ) : (
              <Button
                variation="primary"
                onClick={() => {
                  setUpdate(true);
                  onUpdate(item.connectId);
                }}>
                {'Update'}
              </Button>
            )}
            <Button
              variation="destructive"
              disabled={update}
              onClick={() => {
                onDelete(item.id);
              }}>
              {'Delete'}
            </Button>
          </Flex>
        }
      </TableCell>
    </TableRow>
  );
};
export const ManageTestAccounts: React.FC<{}> = () => {
  const [changeCount, setChangeCount] = useState<number>(0);
  const [accounts, setAccounts] = useState<(ConnectIdLogin | null)[]>([]);

  const updateAccount = async (connectId: string) => {
    const session = await fetchAuthSession();
    const jwt = session.tokens?.idToken;
    if (jwt) {
      const response = await refreshAccountAsync(connectId, jwt.toString());
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    fetchConnectIds().then((items) => {
      if (items !== undefined) {
        setAccounts(items);
      }
    });
  }, [changeCount, setAccounts]);

  return (
    <Flex direction="column">
      <Heading level={4}>{'Add an account, or manage connect Ids'}</Heading>
      <Addcard
        onCreate={async (msisdn: string) => {
          await updateAccount(msisdn);
          setTimeout(() => setChangeCount(changeCount + 1), 1000);
        }}
      />
      <UpdateMany
        title="Update oldest 25"
        onUpdate={updateAccount}
        connectIds={accounts
          .sort((l, r) => {
            return (l?.updatedAt ? Date.parse(l.updatedAt) : 0) - (r?.updatedAt ? Date.parse(r.updatedAt) : 0);
          })
          .filter((_, index) => {
            return index < 25;
          })
          .map((anAccount) => {
            return anAccount!.connectId;
          })}
        setChangeCount={setChangeCount}
      />
      <Table variation="striped" highlightOnHover={true}>
        <TableHead key={'manageTableHead'}>
          <TableRow>
            <TableCell as="th">{'Connect ID'}</TableCell>
            <TableCell as="th">{'Login Details'}</TableCell>
            <TableCell as="th">{'Last update'}</TableCell>
            <TableCell as="th">{'Actions'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts
            .sort((l, r) => parseInt(l!.connectId) - parseInt(r!.connectId))
            .map(
              (item) =>
                item && (
                  <ConnectIdRow
                    item={item}
                    key={item!.id}
                    onDelete={async () => {
                      await deleteConnectId(item);
                      setChangeCount(changeCount + 1); //to trigger a refresh
                    }}
                    onUpdate={async (connectId) => {
                      await updateAccount(connectId);
                      setTimeout(() => setChangeCount((prevCount) => prevCount + 1), 1000);
                    }}
                  />
                ),
            )}
        </TableBody>
      </Table>
    </Flex>
  );
};
